import { AxiosError } from 'axios'
import { useLazyQueryWithAuth } from 'modules/auth/hooks/useLazyQueryWithAuth'
import { LazyQueryOptions } from 'modules/auth/types/queries'
import { EnumBankQueryKeys } from 'modules/bank/queries/keys'
import { ApiError } from 'modules/common/types/error'
import { fetchMonthRangeTransactionsBlob } from '../api/fetchMonthRangeTransactionsBlob'

interface Props {
  accountId: string
  dateFrom: string | undefined
  dateTo: string | undefined
  queryOptions?: LazyQueryOptions<Blob, AxiosError<ApiError>>
}

export const useMonthRangeTransactionsBlobLazyQuery = ({
  accountId,
  dateFrom,
  dateTo,
  queryOptions,
}: Props) => {
  return useLazyQueryWithAuth<Blob, AxiosError<ApiError>>({
    queryKey: [
      EnumBankQueryKeys.MONTH_TRANSACTIONS_BLOB,
      accountId,
      dateFrom,
      dateTo,
    ],
    queryFn: async (_context, accessToken) => {
      if (!dateFrom) {
        throw new Error(
          'useMonthRangeTransactionsBlobLazyQuery: Missing parameter dateFrom'
        )
      }

      if (!dateTo) {
        throw new Error(
          'useMonthRangeTransactionsBlobLazyQuery: Missing parameter dateTo'
        )
      }

      return fetchMonthRangeTransactionsBlob({
        accessToken,
        accountId,
        dateFrom,
        dateTo,
      })
    },
    ...queryOptions,
  })
}
