import { FetchFileDownloadUrlResponseApi } from '@blank/api'
import { formatExportTransactionsDate } from 'modules/bank/utils/format/transactions'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'
import { getBlobFromURL } from 'modules/common/lib/helpers/downloadFile/downloadFile'
import { EnumFileFormat } from 'modules/common/types/file'

interface Props {
  accountId: string
  dateFrom: string
  dateTo: string
  accessToken: string
}

export const fetchMonthRangeTransactionsBlob = async ({
  accountId,
  dateFrom,
  dateTo,
  accessToken,
}: Props) => {
  const apiUrl = `/v1/bank/accounts/${accountId}/transactions/statement`

  const { data } = await apiClient.get<FetchFileDownloadUrlResponseApi>(
    apiUrl,
    {
      params: {
        dateFrom: formatExportTransactionsDate(dateFrom),
        dateTo: formatExportTransactionsDate(dateTo),
        fileFormat: EnumFileFormat.PDF,
        signedUrl: true,
      },
      headers: getAuthorizationHeader(accessToken),
    }
  )

  const blob = await getBlobFromURL(data.file.downloadSignedUrl)

  return blob
}
