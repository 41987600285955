import { buildTimeConfig } from 'config'
import dayjs from 'dayjs'
import { useExportTransactionsSimpleFeedbackAlerts } from 'modules/bank/hooks/transaction/useExportTransactionsSimpleFeedbackAlerts'
import { EnumBankTrackingEvents } from 'modules/bank/lib/tracking/events'
import { useMonthRangeTransactionsBlobLazyQuery } from 'modules/bank/queries/useMonthRangeTransactionsBlobLazyQuery'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { trackEvent } from 'modules/common/lib/amplitude/trackEvent'
import { downloadFileFromBlob } from 'modules/common/lib/helpers/downloadFile/downloadFile'

interface Props {
  accountId: string
  dateFrom: string | undefined
  dateTo: string | undefined
}

export const useExportTransactionsFlow = ({
  accountId,
  dateFrom,
  dateTo,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const {
    showExportTransactionsSuccessAlert,
    showExportTransactionsErrorAlert,
  } = useExportTransactionsSimpleFeedbackAlerts()

  const { fetchQuery: fetchMonthRangeTransactionsBlob } =
    useMonthRangeTransactionsBlobLazyQuery({
      accountId,
      dateFrom,
      dateTo,
      queryOptions: {
        onSuccess: (blob) => {
          if (blob) {
            downloadFileFromBlob({
              blob,
              fileName: t(
                'exportInternalAccountTransactionsModal.transactionsFileTitle',
                {
                  brand: buildTimeConfig.internalBankName.trim().toLowerCase(),
                  from: dayjs(dateFrom).toDate(),
                  to: dayjs(dateTo).toDate(),
                }
              ),
            })
            trackEvent({
              eventType: EnumBankTrackingEvents.SIMPLE_TRANSACTIONS_EXPORT,
            })
            showExportTransactionsSuccessAlert()
          }
        },
        onError: showExportTransactionsErrorAlert,
      },
    })

  return {
    downloadTransactions: fetchMonthRangeTransactionsBlob,
  }
}
